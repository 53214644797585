.ar-email-list{
    margin-top: 10px;
    margin-bottom: 30px;
    height: 250px;
    overflow: auto;
}

.new-user-role-dropdown{
    background: none;
    border: 0;
    box-shadow: none;
    padding: 8px !important;
    margin: -10px !important;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
}

.new-user-remove{
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.2s;
}

.new-user-remove:hover{
    cursor: pointer;
    opacity: 1;
}

.new-user-list td:nth-child(2){
    min-width: 70px;
    max-width: 70px;
}