#site-form {
    display: flex;
    gap: 8px;
}

#site-form-buttons {
    display: flex;
}

.site-add-gateway{
    margin: -10px;
    margin-top: -45px;
}

@media (max-width: 1024px) {
    #site-form-buttons .site-form-btn-text {
        display: none;
    }

    .site-add-gateway{
        margin: 0;
    }
}

@media (max-width: 440px) {
    #site-form {
        flex-direction: column;
        align-items: flex-end;
    }
}