:root{
    --global-margin: 2px;
    --selector-width: 0px;
    --selector-height: 0px;
    --left-pos: 0px;
    --top-pos: var(--global-margin);
}

.selector-container{
    width: max-content;
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    background-color: var(--gray-dark);
    color: #FFF;
    border-radius: 100px;
    cursor: pointer;
}

.selector-disabled{
    cursor: auto;
    opacity: 0.3;
}

.selector-container span{
    padding: 4px 10px 4px 10px;
    user-select: none;
}

.selector-active{
    position: absolute;
    background-color:#ffffff8f;
    border-radius: 100px;
    width: var(--selector-width);
    height: var(--selector-height);
    margin-top: var(--top-pos);
    margin-left: var(--left-pos);
    transition: all 0.2s
}

