#input-and-button input{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#input-and-button button{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

#input-and-button button:hover{
    opacity: 0.8;
    transform: none;
}