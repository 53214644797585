.org-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.org-dropdown-toggle {
  display: inline-flex;
  align-items: center;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 10px;
  outline: none;
  cursor: pointer;
  width: 100%;
}

.org-dropdown-toggle:hover,
.org-dropdown-toggle:focus {
  background: transparent;
  outline: none;
}

.org-toggle-icon {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.org-dropdown-toggle-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.org-dropdown-menu {
  width: 100%;
  box-sizing: border-box;
}

.org-dropdown-item-content {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
}

.org-item-icon {
  margin-left: auto;
}

.org-dropdown-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: block;
}

@media (max-width: 767px) {
  .org-dropdown-toggle {
    display: inline-flex;
    align-items: center;
    border: none;
    box-shadow: none;
    background: transparent;
    padding: 0px;
    outline: none;
    cursor: pointer;
  }
  .org-dropdown-toggle-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 600px;
    display: block;
  }
  .org-dropdown-item-text {
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 600px;
    display: block;
  }
}