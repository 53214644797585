.notification-custom-icon {
  flex-basis: 2%;
  position: relative;
  display: flex;
  align-items: left;
  justify-content: center;
}

.notification-custom-content {
  flex-basis: 80%;
  padding: 12px 12px 12px 8px;
  display: inline-block;
}

.notification-custom-default {
  width: 100%;
  display: flex;
  background-color: #172b4d;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#172b4d, 15%);
  }
}

.notification-custom-primary {
  width: 100%;
  display: flex;
  background-color: #5e72e4;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#5e72e4, 15%);
  }
}

.notification-custom-info {
  width: 100%;
  display: flex;
  background-color: #11cdef;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#11cdef, 15%);
  }
}

.notification-custom-success {
  width: 100%;
  display: flex;
  background-color: #2dce89;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#2dce89, 15%);
  }
}

.notification-custom-danger {
  width: 100%;
  display: flex;
  background-color: #f5365c;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#f5365c, 15%);
  }
}

.notification-custom-warning {
  width: 100%;
  display: flex;
  background-color: #fb6340;
  color: #FFF;

  .notification-custom-icon {
    border-left: 8px solid darken(#fb6340, 15%);
  }
}
