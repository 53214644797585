.map-container {
    position: relative;
    height: 600px;
    width: 100%;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.view-toggle-container {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    z-index: 1000;
}

.view-container {
    position: relative;
    margin-top: 50px;
}

/* List View Controls */
.list-controls {
    margin-bottom: 1.5rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.search-box {
    position: relative;
    flex: 1;
    min-width: 200px;
}

.search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #8898aa;
}

.search-box input {
    padding-left: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid #e9ecef;
    transition: all 0.15s ease;
}

.search-box input:focus {
    border-color: #5e72e4;
    box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.1);
    outline: none;
}

/* Button Group Styles */
.btn-group-consistent {
    display: flex;
}

.btn-fixed-width {
    min-width: 100px;
    height: 38px;
    padding: 0 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.filter-buttons {
    display: flex;
}

.btn-group-consistent .btn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group-consistent .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}

.filter-buttons .btn {
    height: 38px;
    line-height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

/* Button Opacity */
.btn-opacity {
    opacity: 0.6;
}

.btn-opacity:hover {
    opacity: 0.8;
}

/* Table Styles */
.sites-table {
    margin-top: 1rem;
    width: 100%;
    table-layout: fixed;
}

.sites-table th,
.sites-table td {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sites-table th {
    font-size: 0.8125rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1rem;
    background-color: #f6f9fc;
    border-top: 0;
    color: #8898aa;
    text-align: left;
}

.sites-table th:last-child {
    text-align: right;
}

.sites-table td {
    font-size: 0.8125rem;
    padding: 1rem;
    vertical-align: middle;
    border-top: 1px solid #e9ecef;
    text-align: left;
}

.sites-table td:last-child {
    text-align: right;
    padding-right: 2rem;
    min-width: 150px;
}

.sites-table tbody tr:hover {
    background-color: #f6f9fc;
}

.sites-table th i,
.sites-table td i {
    margin-right: 0.5rem;
}

/* Status Indicator Styles */
.status-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-right: 4px;
}

.status-indicator i {
    font-size: 1.25rem;
}

.status-indicator .text-success {
    color: #2dce89 !important;
}

.status-indicator .text-danger {
    color: #f5365c !important;
}

/* Action Buttons Styles */
.action-buttons-container {
    display: inline-flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Table View Action Buttons */
.sites-table .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    transition: all 0.2s ease;
}

@media (max-width: 1199px) {
    .sites-table .btn {
        padding: 0.35rem !important;
        aspect-ratio: 1;
        min-width: 28px;
        height: 28px;
    }

    .sites-table .btn i {
        margin: 0;
        font-size: 0.875rem;
    }

    .sites-table .btn span {
        display: none;
    }
}

@media (min-width: 1200px) {
    .sites-table .btn {
        padding: 0.35rem 0.75rem !important;
        min-width: 80px;
        height: 28px;
    }

    .sites-table .btn i {
        margin-right: 0.35rem;
        font-size: 0.875rem;
    }

    .sites-table .btn span {
        display: inline;
        white-space: nowrap;
        font-size: 0.875rem;
    }
}

/* Compact View Styles */
.compact-site-row {
    background-color: white;
    transition: background-color 0.2s;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem;
}

.compact-site-row .site-info {
    flex: 1;
    min-width: 0;
}

.compact-site-row .site-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.compact-site-row .site-location {
    color: #8898aa;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
}

.compact-site-row:hover {
    background-color: #f6f9fc;
}

/* Empty State Styles */
.text-center.py-5.text-muted {
    color: #8898aa;
}

.text-center.py-5.text-muted i {
    margin-bottom: 1rem;
}

@media (max-width: 991px) {
    .sites-table {
        min-width: 800px;
    }
}

/* Print Styles */
@media print {
    .list-controls {
        display: none !important;
    }

    .sites-table {
        border-collapse: collapse;
    }

    .sites-table th,
    .sites-table td {
        border: 1px solid #ddd;
    }
}