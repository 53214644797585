.chart-container {
  text-align: -webkit-center;
}

.plot {
  height: 80vh;
  width: 99%;
  min-height: 640px;
}

.legend-container {
  position: relative;
  top: -18vh;
  left: -70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 68px;
}

.legend-info {
  margin-bottom: 2px;
  margin-left: 8px;
  margin-top: 5px;
  font-size: 14px;
  color: #535353;
}

.legend-tip {
  white-space: pre-wrap;
  margin-right: 50px;
  font-size: 12px;
}

.legend {
  display: grid;
  grid-auto-rows: 30px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.last-update,
.last-update-none {
  display: block;
  margin-top: 20px;
  margin-right: 20px;
  text-align: end;
}

.last-update-none {
  display: none;
}

.legend-item {
  cursor: pointer;
  text-align: left;
  color: #535353;
  font-size: 14px;
  white-space: nowrap;
  height: 38px;
  max-width: 76vw;
  transition: background-color, 0.15s;
}

.legend-item-not-visible {
  cursor: pointer;
  text-align: left;
  color: #cccccc;
  font-size: 14px;
  white-space: nowrap;
  height: 38px;
  max-width: 76vw;
  transition: background-color, 0.15s;
}

.legend-item:hover,
.legend-item-not-visible:hover {
  background: #e8e8e8;
  border-radius: 6px;
}

.legend-value {
  font-weight: bold;
}

@media (max-width: 768px) {
  .legend-container {
    left: -16px;
    width: auto;
    margin-left: 8px;
  }

  .legend-info {
    margin-left: 8px;
  }

  .legend {
    margin-left: 8px;
  }

  .legend-item,
  .legend-item-not-visible {
    overflow: scroll;
  }
}
