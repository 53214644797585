.kpi-01-header{
    display: grid;
    grid-template-columns: 260px auto auto;
    margin-bottom: 30px;
    align-items: center;
    justify-content: left;
    gap: 40px;
}

.kpi-01-interval{
    display: flex;
    align-items: center;
}

.kpi-01-interval-inputs{
    display: grid;
    width: min-content;
    align-items: center;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 4px; 
    grid-template-areas: 
    "kpi-01-from kpi-01-input-from"
    "kpi-01-to kpi-01-input-to"
    ". ."; 
}

.kpi-01-from { grid-area: kpi-01-from; width: 40px; }
.kpi-01-input-from { grid-area: kpi-01-input-from; }
.kpi-01-to { grid-area: kpi-01-to; width: 40px; }
.kpi-01-input-to { grid-area: kpi-01-input-to; }

.kpi-01-result{
    display: grid;
    grid-template-columns: 60% auto;
}

.kpi-01-table{
    height: 54vh;
    overflow: auto;
}

.kpi-01-table{
    text-align: center;
}

.kpi-01-gw{
    text-align: left;
}

.gw-kpi-01[data-type='no-data']{
    background: #eee !important;
}

.gw-kpi-01[data-type='top']{
    background: #00ff00 !important
}

.gw-kpi-01[data-type='high']{
    background: #b3ff00 !important
}

.gw-kpi-01[data-type='medium']{
    background: #ffea00 !important
}

.gw-kpi-01[data-type='low']{
    background: #ff9900 !important
}

.gw-kpi-01[data-type='bottom']{
    background: #ff0000 !important
}

.gw-kpi-01:hover{
    cursor: pointer;
    background: #e2f8ff !important;
}