.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-dashboard-map{
  flex-grow: 1;
  flex-basis: 1;
  z-index: 0;
}

.site-ok{
  color: #0E5191;
}

.site-warn{
  color: #ffab02;
}

.site-alarm{
  color: #FF0000;
}

.site-offline{
  color: #000;
}

@media (max-width: 540px) {
  .device-name {
    font-size: 16px;
  }

  .reference {
    font-size: 12px;
  }

  .icon-device-info {
    max-width: 0px;
    margin-right: 48px;
  }
}
