.deleted-reference {
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.delete-anag {
  float: right;
  margin-top: -80px;
  display: grid;
}

.deleted-devices-trash {
  color: lightgray;
  transition: all 0.3s;
  cursor: pointer;
  text-align: end;
}

.deleted-devices-trash:hover {
  color: grey;
}

.deleted-thresholds {
  cursor: pointer;
  transition: opacity 0.3s;
}

.deleted-thresholds:hover {
  opacity: 0.8;
}

.entity-container {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: -40px;
}

@media (max-width: 1660px) {
  .entity-container {
    display: block;
  }
}
