.par-list {
  max-height: 58vh;
  overflow: auto;
}

.par-label-list {
  padding: 0;
  margin-left: -4px;
  border: none;
  color: #5c6786;
}

.par-value-container {
  max-width: 10px;
}

.par-value {
  border: none;
  max-width: 90px;
  color: #5c6786;
}

.par-item {
  cursor: pointer;
  color: #5c6786;
}

.par-error-count {
  white-space: nowrap;
}

.btn-toggle-modal {
  display: none;
}

.par-actions {
  display: grid;
}

.confirm-write-buttons {
  margin-top: 40px;
}

.dongle-par-column-group {
  width: 20%;
}

.dongle-par-column-label {
  width: 10%;
}

.dongle-par-column-description {
  width: 60%;
}

.dongle-par-column-value {
  width: 10%;
}

.dongle-par-buttons-container {
  margin-bottom: 1.2rem;
}

@media (max-width: 1660px) {
  .par-buttons-wide {
    display: none;
  }

  .btn-toggle-modal {
    display: unset;
  }

  .btn-parameters {
    margin: 5px;
  }
}

@media (max-width: 430px) {
  .par-error-count {
    float: right;
  }
}
