.ar-container {
    display: flex;
    height: auto;
}

.ar-section-left,
.ar-section-right {
    display: flex;
    flex-direction: column;
    max-height: 72vh;
}

.ar-section-left {
    flex: 0 0 400px;
    margin-right: 20px;
}

.ar-section-right {
    flex: 1;
}

.ar-section-top {
    padding: 10px 0;
}

.ar-section-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
}

.ar-entity-list-container {
    flex: 1;
    overflow-y: auto;
}

.ar-details-list{
    display: flex;
    height: 66vh;
    justify-content: space-between;
    flex-direction: column;
}

/* Components */
.ar-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.ar-selector-item {
    text-align: center;
    padding: 8px;
    border-bottom: 3px solid #00000000;
}

.ar-selector-item:hover {
    background-color: #f5f5f5;
}

.ar-selector-item.active {
    background-color: #f5f5f5;
    border-bottom-color: #5E72E4;
}

.ar-role-dropdown {
    background: #00000000;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 6px 10px;
    min-width: 120px;
}

.selector-one-item {
    text-align: center;
    font-weight: bold;
    padding: 8px;
    background-color: #f5f5f5;
    border-bottom-color: #5E72E4;
    border-radius: 6px;
}

/* Group styles */
.group-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1px 5px 1px 10px;
}

.group-info {
    flex: 1;
    min-width: 0;
    margin-right: 10px;
}

.group-toggle-container {
    flex: 0 0 30px;
    display: flex;
    justify-content: flex-end;
}

.group-toggle {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
}

.group-toggle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #adb5bd;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-out;
}

.group-toggle.open::before {
    transform: translate(-50%, -50%) rotate(-180deg);
}

.bg-info .group-toggle::before {
    border-top-color: white;
}

.user-select-none {
    user-select: none;
}

.pointer {
    cursor: pointer;
}

.transition-all {
    transition: all 0.2s ease;
}

.text-overflow {
    max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1200px) { /* If you want to change this value, you must also change the SMALL_SCREEN_WIDTH value in js file */
    .ar-container {
        display: block;
    }

    .ar-section-left {
        width: auto;
        margin-right: auto;
    }

    .ar-section-right {
        display: none;
    }

    .ar-section-top {
        height: auto;
    }
}