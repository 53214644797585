.sidebar-container, .sidebar-full-height{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sidebar-docs-toggle{
  background: none;
  border: none;
  box-shadow: none;
  color: #00000080;
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: 18px;
}

.sidebar-docs-toggle:hover{
  color: #000000cc;
}

.sidebar-docs-menu{
  position: absolute;
  display: grid;
  padding: 14px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #0000001a;
  transform: translate(0, -20px) rotateX(90deg) ;
  transition: all 0.2s;
}

/* Default for mobile */
.sidebar-docs-menu.show {
  transform: translate(0, -100px) rotateX(0deg);
}

.sidebar-profile-text {
  max-width: 25vw;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Adjust for non-mobile devices */
@media (min-width: 768px) {
  .sidebar-docs-menu.show {
    transform: translate(0, -120px) rotateX(0deg);
  }
}

.sidebar-docs-menu a{
  padding: 10px;
  color:#00000080;
}

.sidebar-docs-menu a:hover{
  color:#000000cc;
}

.sidebar-docs-separator {
  display: none;
}


@media (max-width: 767px) {
  .sidebar-container {
    display: flex;
    flex-direction: initial;
    flex-grow: initial;
    justify-content: space-between;
    width: 100vw;
    background-color: initial;
  }

  .sidebar-menu {
    text-align: left;
    width: 30vw;
  }
  .sidebar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
  }
  .sidebar-user {
    display: block;
    text-align: center;
    width: 30vw;
  }

  .sidebar-docs-separator {
    display: block;
  }
}

.menu-separator {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #909090;
  opacity: 0.3;
}

.item-right-icon{
  position: absolute;
  right: 15px;
  color: var(--warning);
}

.register-new-gateway{
  cursor: pointer;
}