.report-info,
.report-info-single {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 50% auto;
}

.report-info-single {
    display: block;
}

.report-output,
.report-controllers {
    margin-top: 10px;
    display: flex;
}

.report-output-dropdown,
.report-controllers-dropdown {
    width: 100%;
}

.report-thresholds {
    display: grid;
    grid-template-columns: 60% 20%;
}

.report-label,
.report-dates-measures {
    width: -webkit-fill-available;
    width: -moz-available;
}

.report-label {
    margin-left: 12px;
}

.report-threshold-name {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.report-dates,
.report-dates-measures {
    display: flex;
    align-items: center;
}

.report-dates-dropdown {
    width: auto;
    min-width: 180px;
}

.report-btn-sub{
    width: fit-content;
}

@media (max-width: 518px) {
    .report-thresholds {
        margin-left: 10px;
    }

    .report-info {
        display: flex;
        flex-direction: column;
    }

    .label-0 {
        order: 0;
        width: max-content;
    }

    .report-output {
        order: 1;
        width: auto;
        margin-left: 0;
    }

    .report-output-dropdown {
        width: 100%;
    }

    .label-1 {
        order: 2;
        margin-top: 20px;
    }

    .report-controllers {
        order: 3;
        width: auto;
        margin-left: 0;
    }

    .report-controllers-dropdown {
        width: 100%;
    }

    .report-output-toggle,
    .report-controllers-toggle {
        width: -webkit-fill-available !important;
        width: -moz-available !important;
        width: -ms-available !important;
    }

    .report-dates-measures {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
      "report-dates report-dates"
      "date-from date-to";

        justify-items: center;
    }

    .report-dates-dropdown {
        grid-area: report-dates;
        width: -webkit-fill-available !important;
        width: -moz-available !important;
        width: -ms-available !important;
    }

    .date-from {
        grid-area: date-from;
    }

    .date-to {
        grid-area: date-to;
        min-width: max-content;
    }
}

@media (max-width: 460px) {
    .report-threshold-value {
        min-width: 62px;
    }
}

@media (max-width: 366px) {
    .report-dates {
        display: block;
        text-align: center;
    }

    .report-dates-measures {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
      "report-dates"
      "date-from"
      "date-to";
    }

    .report-output {
        display: grid;
        gap: 10px;
    }
}


.reports-container{
    margin: 10px;
    align-items: center;
    overflow: auto;
}

.report-label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.report-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.report-row .report-section {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.scheduled-report-grid-container {
    width: 100%;
    padding: 8px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
}

.scheduled-report-time-input {
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
}

.scheduled-report-output-dropdown {
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
}

.scheduled-report-container {
    width: 100%;
    padding: 8px;
}
