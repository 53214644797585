.chart-type {
  display: grid;
  align-items: center;
  justify-content: space-around;
  background: #f5f5f5;
  border-radius: 0.375rem;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-wrap: wrap;
}

.chart-type-wide,
.report-conf {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f5f5f5;
  border-radius: 0.375rem;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-wrap: wrap;
}

.input-date {
  border: 1px solid #eaebec;
  border-radius: 0.375rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-transform: capitalize;
  max-width: 142px;
  margin: 5px;
}

.input-time {
  border: 1px solid #eaebec;
  border-radius: 0.375rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-transform: capitalize;
  max-width: 102px;
  margin: 5px;
}

select {
  border: 1px solid #eaebec;
  border-radius: 0.375rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-transform: capitalize;
}

#separator {
  line-height: 50px;
  text-align: center;
}

.load-icon {
  display: none;
  text-align: center;
  justify-content: center;
}

.button-show {
  display: none;
}

.card-gateway {
  text-align: left;
}

.license-data {
  display: flex;
}

@media (max-width: 1318px) {
  .load-text {
    /* display: none; Switch comments to show the icon */
    display: block;
  }
  .load-icon {
    /* display: block; Switch comments to show the icon */
    display: none;
  }

  .button-show {
    display: block;
    margin: 15px;
  }

  .chart-type-wide {
    display: none;
  }

  .card-gateway {
    text-align: center;
  }

  .card-devices {
    text-align: left;
  }

  .license-data {
    display: grid;
  }
}

.input-telemetry-label {
  margin-left: 32px;
  margin-right: 6px;
  font-size: 14px;
  /*color: #8898aa;*/
}

.input-telemetry-value {
  max-height: 50px;
  font-size: 14px;
  color: #8898aa;
}

@media (max-width: 422px) {
  .input-telemetry {
    display: grid;
    max-width: 378px;
  }

  .input-telemetry-label {
    margin-left: 32px;
    margin-right: 6px;
    font-size: 14px;
  }

  .input-telemetry-value {
    margin-left: 20px;
    max-height: 50px;
    font-size: 14px;
    color: #8898aa;
  }
}

/*
 PermissionSettings style
*/

.permission-btn-add-user {
  width: fit-content;
  justify-self: right;
  margin-top: 15px;
  right: 15px;
}

.permission-user-nav-link {
  text-decoration: none;
  color: #444;
  cursor: pointer;
}

.permission-user-list {
  width: auto;
}

.permission-remove-email-list {
  display: block;
  text-align-last: start;
}

.permission-form {
  display: grid;
  grid-template-columns: auto 30%;
}

.permission-search {
  display: grid;
  grid-template-columns: auto 10%;
}

.permission-buttons {
  display: grid;
  grid-template-columns: auto auto;
}

.permission-search-bar,
.permission-search-btn {
  border-radius: 2rem;
}

.permission-search-btn {
  right: 155px;
  height: 46px;
  width: 158px;
}

.permission-search-btn:hover {
  transform: none;
}

.permission-modal {
  min-width: 886px;
}

.permission-modal-body {
  width: 870px;
  background-color: #f5f5f5;
  border: 8px solid #fff;
}
.permission-toggle-list {
  margin: 0;
  margin-top: 20px;
}

@media (max-width: 1380px) {
  .permission-form {
    display: block;
  }

  .permission-search {
    display: flex;
  }

  .permission-search-btn {
    position: absolute;
    right: -10px;
  }

  .permission-buttons {
    margin-top: 20px;
  }
}

@media (max-width: 902px) {
  .permission-modal,
  .permission-modal-body {
    min-width: auto;
    width: auto;
  }
}

@media (max-width: 440px) {
  .permission-user-list {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@media (max-width: 386px) {
  .permission-search {
    display: flex;
    flex-direction: column;
  }

  .permission-search-dropdown {
    display: flex;
    align-self: flex-end;
  }

  .permission-search-btn {
    position: relative;
    margin: 0;
  }

  .permission-buttons {
    display: block;
  }

  .permission-btn {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .permission-btn:last-child {
    margin-top: 10px;
  }
}
