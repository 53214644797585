.modal-preferences {
    width: auto !important;
  }
  
  .modal-preferences-body {
    height: 300px;
  }
  
  .modal-preferences-close {
    position: absolute;
    right: 18px;
    top: 12px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .modal-preferences-close:hover {
    transform: scale(1.2);
  }
  
  .modal-preferences-close:active {
    transform: scale(0.7);
  }
  
  .modal-preferences-body:before {
    content: "";
    z-index: -1;
    position: absolute;
    width: 102%;
    height: 102%;
    border-radius: 10px;
    background: #cad1d7;
    transform: translate(-50%, -50%);
    opacity: 0.4;
    top: 50%;
    left: 50%;
  }

  .modal-preferences-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .preferences-container{
    display: grid;
    margin-top: 20px;
    margin-left: 20px;
    grid-template-columns: min-content min-content;
    align-items: center;
    gap: 20px;
    max-height: 200px;
    overflow: auto;
  }

  .btn-preferences-apply {
    margin-top: 10px;
    float: right;
  }
  
  .filter-language {
    border-radius: 10px 10px 0 0;
  }
  
  .filter-invisible {
    height: 0;
    background: #eceff1;
  }
  
  .lang-container {
    border: 1px solid #cad1d7;
    border-radius: 0 0 10px 10px;
    border-top: none;
    max-height: 150px;
    overflow: auto;
  }
  
  .lang-item {
    cursor: pointer;
    padding: 8px;
    padding-left: 18px;
    display: flex;
  }


@media (max-width:366px){
  .preferences-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .preferences-container .dropdown{
    margin-left: 30px;
  }
}