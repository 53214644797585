.variable-filters {
  display: flex;
  flex-direction: row;
  max-width: 50%;
}

.variable-list-selection button {
  margin: 0;
}

.card-point {
  cursor: pointer;
}

.card-point:hover {
  background: #f0f0f0;
}

.card-value {
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 20px;
  width: 140px;
  height: 78px;
  text-align: left;
  border-left: 1px solid #eeeeee;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: table;
}

.card-value-on {
  background: var(--success);
  color: #fff;
}

.card-value span {
  display: table-cell;
  vertical-align: middle;
}

.analog-new-value {
  position: absolute;
  max-width: 56px;
  top: 15px;
  right: 140px;
}

.analog-new-value:hover {
  max-width: 160px;
}

.card-commands {
  position: absolute;
  right: 150px;
  display: flex;
  flex-direction: column;
  top: 25px;
}

.details-container {
  display: flex;
  justify-content: center;
}

.details {
  position: fixed;
  top: var(--details-pos);
  margin-left: 10px;
  margin-top: 18px;
  padding: 10px;
  border: 2px solid #f5f5f5;
  border-radius: 20px;
  box-shadow: 6px 6px 20px 4px #f5f5f5df;
}

.icon-details {
  display: none;
}

.icon-details-xs {
  display: none;
}

.modal-chart {
  position: absolute;
  left: 25px;
}

.outer-circle {
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
}

.inner-circle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  border-radius: 40px;
}

.outer-circle-disabled {
  border-color: #cad1d7;
}

.inner-circle-disabled {
  background-color: #e9ecef;
}

@media (max-width: 1550px) {
  .card-point:hover {
    background: #fff;
  }

  .details-container {
    display: none;
  }

  .details {
    display: none;
  }

  .variable-filters {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  .icon-details {
    display: block;
    margin-right: 10px;
    color: var(--light);
  }
}

.send-value-loader {
  position: absolute;
  right: 45px;
  top: 14px;
}

.card-name-sm {
  display: none;
}

.customization-large{
  display: flex;
}

.customization-small{
  display: none;
}

.customization-dropdown-item {
  display: grid;
  grid-template-columns: 25px 1fr;
}

.card-name-edit{
  height: 30px;
}

.card-name-reset{
  cursor: pointer;
}

@media (max-width: 992px) {
  .card-name-sm {
    display: block;
  }

  .card-name {
    display: none;
  }

  .card-value-container {
    align-self: center;
    min-height: 85px;
    max-height: 85px;
  }

  .card-value {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
  }
  .analog-new-value {
    top: 60px;
    right: 10px;
  }

  .card-commands {
    top: 72px;
    right: 10px;
  }

  .alarm-icon {
    font-size: 0px;
  }

  .send-value-loader {
    position: relative;
    justify-content: space-between;
    top: -8px;
    right: -8px;
  }

  .icon-details {
    display: none;
  }

  .icon-details-xs {
    display: block;
    position: absolute;
    color: var(--light);
    right: 20px;
    top: 10px;
  }

  .customization-large{
    display: none;
  }
  
  .customization-small{
    display: block;
  }

  .card-name-edit{
    width: 95%;
  }

  .card-name-edit-sm{
    display: none;
  }
}

@media (max-width: 500px) {
  .variable-filters{
    display: flex;
    flex-direction: column;
  }
}