.fill-page {
  margin: 10px;
}

.options-container {
  height: 78vh;
}

.filter-container {
  background: #f5f5f5;
  border-radius: 0.375rem;
  padding-top: 8px;
  padding-bottom: 8px;
  display: grid;
}

.filter-options-container {
  display: grid;
  grid-template-columns: 30% 100%;
}

.filter-text {
  line-height: 50px;
  margin-left: 30px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
}

.input-date-log {
  border: 1px solid #eaebec;
  border-radius: 0.375rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-transform: capitalize;
  max-width: 142px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.input-time-log {
  border: 1px solid #eaebec;
  border-radius: 0.375rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  text-transform: capitalize;
  max-width: 102px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-list {
  margin-right: 20px;
  max-width: 264px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.btn-apply-filter {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 52px;
}

.table-container {
  margin: 25px;
  margin-top: 5px;
  max-height: 60vh; /* you can change as you need it */
  overflow: auto; /* to get scroll */
  cursor: pointer;
}

.col-header {
  display: grid;
  grid-template-columns: 99% 1%;
  white-space: normal;
}

.no-alarms {
  background: #fff;
  text-align: center;
}

.filter-info {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.filter-items {
  font-weight: bold;
  display: grid;
  margin-left: 180px;
  grid-template-columns: 15% 22% 27% 22% 31% 22%;
}

.filter-time-container {
  display: flex;
  flex-direction: row;
}

.filter-time-from {
  font-weight: bold;
}

.filter-time-to {
  font-weight: bold;
}

.filter-time {
  font-weight: normal;
  margin-left: 10px;
  margin-right: 20px;
}

#sites,
#gateways,
#controllers {
  font-weight: normal;
}

.live-alarms-on {
  text-align: right;
  margin-top: 15px;
  margin-right: 36px;
  font-size: 15px;
}

.live-alarms-on-label {
  font-weight: bold;
  margin-right: 10px;
}

@media (max-width: 1550px) {
  .filter-info {
    display: grid;
    grid-template-columns: 30% 45%;
  }
  .filter-items {
    margin-left: 80px;
    grid-template-columns: 42% 40%;
  }
}

@media (max-width: 1024px) {
  .filter-info {
    display: grid;
    grid-template-columns: 100%;
    margin-left: 40px;
  }

  .filter-items {
    margin: 0px;
    grid-template-columns: 48% 40%;
  }
}

@media (max-width: 767px) {
  .filter-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter-text {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .input-date-log {
    margin: 0px;
  }

  .input-time-log {
    margin: 0px;
  }
}
