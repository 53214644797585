/* #device-info-table {
  width: 100%;
} */

/* #device-info-header {
  padding: 8px;
  white-space: nowrap;
  width: 1%;
} */

/* #device-info-data {
  padding: 8px;
} */

.model-info {
  display: flex;
}

.model-data {
  margin-left: 1rem ;
  min-width: 200px;
  max-width: 380px;
}

.persona-data {
  display: flex;
}

.device-buttons {
  display: flex;
  flex-direction: row;
}

.model-serial {
  margin-left: 1rem ;
}

.form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1540px) {
  .model-serial {
    margin-left: 0rem ;
  }
  .model-info {
    display: block;
  }

  .model-data {
    margin-left: 0rem ;
    min-width: auto;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .model-serial {
    margin-left: 0rem ;
  }
  
  .persona-data {
    display: block;
  }

  .model-info {
    display: block;
  }

  .model-data {
    margin-left:0rem ;
  }
}

/* @media (max-width: 990px) {
  #device-info-row {
    display: grid;
    grid-template-columns: 50% 50%;
  }
} */

@media (max-width: 490px) {
  .model-serial {
    margin-left: 0;
  }
  .device-buttons {
    display: flex;
    flex-direction: column;
  }
  .model-data {
    margin-left:0rem ;
  }
}
