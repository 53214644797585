.dropdown-toggle {
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.dropdown-menu {
  width: -webkit-fill-available;
  width: -moz-available;
  max-height: 280px; /* you can change as you need it */
  overflow: auto; /* to get scroll */
}

.dropdown-header {
  background: #f7fbff;
  color: #505f6e;
}
