.search-result {
  position: absolute;
  top: 80px;
  right: 175px;
    width: 300px;
  border-left: 6px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  max-height: 240px;
    /* you can change as you need it */
  overflow: auto; /* to get scroll */
}

.search-result-sm {
  display: none;
}

.dropdown-user {
  position: absolute !important;
  z-index: 10 !important;
  min-width: fit-content;
}

.cursor-default {
  cursor: default;
}

@media (max-width: 990px) {
  .search-result {
    top: 65px;
    left: 65px;
    max-height: 440px;
  }
}

@media (max-width: 766px) {
  .search-result {
    display: none;
  }

  .search-result-sm {
    position: absolute;
    display: block;
    z-index: 1000;
    right: 65px;
    top: 102px;
    min-width: 200px;
    max-height: 280px; /* you can change as you need it */
    overflow: auto; /* to get scroll */
  }
}

.search-item {
  padding: 10px;
  cursor: pointer;
  background-color: #fafbffea;
  backdrop-filter: blur(3px);
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 500;
}

.search-item-title {
  display: flex;
  gap: 6px;
}
.search-item:hover {
  background-color: #dadbdfea;
}

.search-item-subtitle {
  font-size: 0.78rem;
  font-weight: 100;
}
