
.subscription-tab-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 24px;
}

.subscription-tab{
    font-weight: unset;
    background: #FAFAFA;
    color: #888;
    border-bottom: none;
    text-align: center;
    font-size: 16px;
}

.badge-text{
    text-align: center;
    color: white;
    font-size: 10px;
    width: 100%;
}
.badge-text-expired{
    text-align: center;
    color: gray;
    font-size: 10px;
    width: 100%;
}

.sub-tab-text{
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: black;
    font-size: 14px;
}

.sub-tab-text-expired{
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: gray;
    font-size: 14px;
}

.sub-details-container{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding-top: 36px; 
}

.sub-details-name{
    text-align: left;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
}

.sub-details-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border: none;
}

.sub-details-item-left{
    text-align: left;
    padding: 4px 8px;
    font-size: 14px;
}

.badge-text-details{
    text-align: center;
    color: white;
    font-size: 10px;
    width: 100px;
}
.badge-text-expired-details{
    text-align: center;
    color: gray;
    font-size: 10px;
    width: 100px;
}

.features-section {
    margin-top: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.features-container {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 0.5rem;
}

/* Prevent scrolling on larger screens with sufficient space */
@media (min-height: 900px) {
    .features-container {
        overflow-y: visible;
    }
}

.feature-items {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: none;
    background-color: #f8f9fa; /* bg-lighter */
    white-space: nowrap;
}

.feature-items span:first-child {
    flex: 1; /* Allow feature name to take available space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent wrapping */
}
