.adrsearch-results {
  margin-top: -20px;
  border-left: 6px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  max-height: 140px; /* you can change as you need it */
  overflow: auto; /* to get scroll */
}

.adrsearch-item {
  margin: 10px;
  cursor: pointer;
  background-color: #fafbffea;
  backdrop-filter: blur(3px);
  color: #525f7f;
  font-size: 0.8rem;
  font-weight: 500;
}

.adrsearch-item:hover {
  background-color: #dadbdfea;
}