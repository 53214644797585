.modal-alerts {
  min-width: 32vw;
}

.threshold-inputs{
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.threshold-input{
  display: flex;
  flex-direction: row;
  gap:2px
}

.search-item-alarm{
  position: absolute;
  left: 45px;
}